<template>
  <article-page :menu="menu">
    <div class="training-content" id="section-inviting">
      <div class="_subtitle">
        Приглашение пользователей
      </div>
      <span>
        Вы можете работать с закупками совместно с Вашими сотрудниками, делегировать закупки коллегам.
      </span>
      <span>
        В зависимости от тарифа, Ваш аккаунт может предусматривать одновременную работу нескольких пользователей.
        По запросу, максимальное количество пользователей, работающих совместно, может быть увеличено.
      </span>
      <span>
        Для того, чтобы пригласить пользователя, перейдите в раздел
        <accessed-link :name="Tabs.Settings.UsersManagement" target="_blank"> Управление пользователями </accessed-link>
        и кликните по ссылке <b>"Добавить пользователя"</b>.
      </span>
      <article-image :src="images[0].src" :alt="images[0].alt" @click="openViewer(images, 0)"/>
      <span>
        В диалоге приглашения введите данные нового пользователя - фамилия, имя, телефон и email, на который будет направлено приглашение, затем нажмите кнопку <b>"Добавить"</b>.
        В случае, если приглашение успешно отправлено, Вы получите соответствующее уведомление.
      </span>
      <span>
        Существует несколько уровней доступа:
      </span>
      <ul>
        <li>Администратор;</li>
        <li>Чтение и запись;</li>
        <li>Чтение и запись (локально);</li>
        <li>Только чтение;</li>
        <li>Заблокирован;</li>
      </ul>
      <span>
        По умолчанию новому пользователю устанавливается роль <b>чтение и запись</b>, при приглашении пользователя ее можно повысить, понизить или оставить.
      </span>
      <article-image :src="images[1].src" :alt="images[1].alt" width="600px" @click="openViewer(images, 1)"/>
    </div>
    <div class="training-content" id="section-editing">
      <div class="_subtitle">
        Редактирование данных пользователей
      </div>
      <span>
        Для изменения уровня доступа или контактных данных, нажмите на иконку
        <ui-icon :icon="UiIconNames.Icon_Edit" :size="14" />
        в соответствующей строке таблицы пользователей.
      </span>
      <article-image :src="images[2].src" :alt="images[2].alt" @click="openViewer(images, 2)"/>
    </div>
    <div class="training-content" id="section-user-access">
      <div class="_subtitle">
        Уровни доступа
      </div>
      <span>
        Пользователь с доступом <b>"Чтение и запись"</b> наделяется следующими правами:
      </span>
      <ul>
        <li>Назначать/переназначать ответственного на закупку;</li>
        <li>Переводить статусы закупки;</li>
        <li>Комментировать закупки;</li>
      </ul>
      <article-image :src="images[3].src" :alt="images[3].alt" @click="openViewer(images, 3)"/>
      <span>
        Пользователь с ролью <b>"Чтение и запись (локально)"</b> может редактировать данные только своих закупок.
      </span>
      <span>
        Пользователь с ролью <b>"Только чтение"</b> видит все заявки, контракты и шаблоны компании, может читать комментарии, однако у него отсутствуют права на запись.
      </span>
    </div>
    <div class="training-content" id="section-blocking">
      <div class="_subtitle">
        Блокировка пользователей
      </div>
      <span>
        Системой предусмотрена возможность блокировки пользователя.
        Для этого нажмите "Редактирование данных пользователя" (иконка <ui-icon :icon="UiIconNames.Icon_Edit" :size="14" /> в соответствующей строке таблицы пользователей).
        Выберите доступ <b>"Заблокирован"</b> и нажмите <b>"Сохранить изменения"</b>.
        Заблокированный пользовать не имеет доступа ни к просмотру, ни к редактированию данных компании.
      </span>
      <span>
        При необходимости, доступ можно поменять обратно в любой момент времени.
      </span>
      <article-image :src="images[4].src" :alt="images[4].alt" width="600px" @click="openViewer(images, 4)"/>
      <alert-message type="warning" full-info>
        <template #content>
          Обратите внимание, в тарифах предусмотрено определенное количество активных пользователей.
          Под активными пользователями подразумеваются все незаблокированные аккаунты. При этом количество зарегистрированных пользователей
          в компании может быть больше.
        </template>
      </alert-message>
    </div>
  </article-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import ArticlePage from "@/components/pages/training/ArticlePage.vue";
import ArticleImage from "@/components/pages/training/ArticleImage.vue";
import { useImageViewer } from "@/use/other/useImageViewer";
import { Tabs } from "@/router/tabs";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import AccessedLink from "@/components/ui/links/AccessedLink.vue";
import AlertMessage from "@/components/ui/alert/AlertMessage.vue";

export default defineComponent({
  name: "UserManagementArticle",
  components: {
    AlertMessage,
    AccessedLink,
    ArticleImage,
    ArticlePage,
    UiIcon,
  },
  setup() {

    const menu = [
      { id: 'section-inviting', title: 'Приглашение пользователей' },
      { id: 'section-editing', title: 'Редактирование данных пользователей' },
      { id: 'section-user-access', title: 'Доступ пользователей' },
      { id: 'section-blocking', title: 'Блокировка пользователей' },
    ]

    const images = [
      { src: 'settings/user-management/invite-user.png', alt: 'Добавление нового пользователя' },
      { src: 'settings/user-management/invite-user-dialog.png', alt: 'Диалог добавления нового пользователя' },
      { src: 'settings/user-management/edit-user.png', alt: 'Редактирование данных пользователя' },
      { src: 'apps/app-responsible.png', alt: 'Назначение ответственного' },
      { src: 'settings/user-management/block-user.png', alt: 'Блокировка пользователя' },
    ]

    const { openViewer } = useImageViewer();

    return {
      menu,
      images,
      Tabs,
      openViewer,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/training';
</style>
